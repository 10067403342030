import React from "react";
import Banner from "../components/common/Banner";
import banner from "../assets/part.jpg";
import BottomCarousel from "../components/common/BottomCarousel";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div>
      {/* Banner Section */}
      <Banner pageName="Privacy Policy" backgroundImage={banner} />

      {/* Privacy Policy Section */}
      <div className="bg-white md:p-10 p-5">
        <div className="max-w-6xl mx-auto px-5">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">
            Privacy Policy
          </h1>
          <div>
            <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
              Global Auto Experts certifies that your information is safe and
              secure when making transactions over the Internet.
            </p>
            <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
              Through this Internet Privacy Statement, we want to reassure users
              of the Site that we will not sell, share, or rent user information
              to others in a manner different than as set forth in this Internet
              Privacy Statement.
            </p>
            <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
              We use a credit card processing company to bill purchasers of
              goods from the Site, and we also use outside distributors and
              delivery companies to assist us in fulfilling and delivering
              orders. We share with them only the information necessary to
              enable such card processing and fulfillment and delivery of goods.
              Our users' personal information will not be shared, sold, or
              rented to any organization outside of Global Auto Experts and our
              financial and order fulfillment affiliates.
            </p>
            <p className="text-gray-700 text-lg text-justify leading-7">
              Global Auto Experts occasionally sends our customers and users of
              the Site announcements and updates, containing important
              information which we believe will be of value to them. Users of
              the Site can always update their personally identifying
              information that they have provided to Global Auto Experts by
              e-mailing us at{" "}
              <a
                href="mailto:info@globalautoexperts.us"
                className="text-blue-500 underline"
              >
                info@globalautoexperts.us
              </a>
              .
            </p>
          </div>
        </div>
        <div className="max-w-6xl mx-auto px-5">
          <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
            Global Auto Experts ("we", "our", or "us") is committed to
            protecting your privacy. This Privacy Policy describes how we
            collect, use, disclose, and protect your information when you visit
            our website, www.globalautoexperts.us ("Website"), or interact with
            us in any other manner.
          </p>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Information We Collect
          </h2>
          <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
            We may collect the following types of information from you:
          </p>
          <ul className="list-disc pl-5 mb-4 text-gray-700 text-lg leading-7">
            <li>
              <strong>Personal Information:</strong> When you contact us through
              our Website, phone, or email, we may collect personal information
              that you provide, including your full name, phone number, email
              address, and mailing address.
            </li>
            <li>
              <strong>Automatically Collected Information:</strong> When you
              visit our Website, we may automatically collect certain
              information about your device and usage, including IP address,
              browser type, operating system, referring URLs, and pages viewed.
            </li>
          </ul>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            How We Use Your Information
          </h2>
          <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
            We use the information we collect for the following purposes:
          </p>
          <ul className="list-disc pl-5 mb-4 text-gray-700 text-lg leading-7">
            <li>
              <strong>To Communicate with You:</strong> We use your contact
              information to respond to your inquiries, provide services, send
              administrative information, and keep you informed about our
              services.
            </li>
            <li>
              <strong>Marketing and Promotional Communications:</strong> With
              your consent, we may use your information to send you updates,
              newsletters, or marketing communications via email, phone, or text
              message. You can opt out of receiving these communications at any
              time by following the instructions provided in the communication
              or contacting us directly.
            </li>
            <li>
              <strong>Legal Compliance:</strong> We may use your information to
              comply with applicable laws, regulations, or legal obligations,
              including responding to subpoenas, court orders, or legal
              requests.
            </li>
          </ul>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Consent to Receive Text Messages
          </h2>
          <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
            You are not required to consent to receiving text messages from
            Global Auto Experts. By providing your phone number and opting in,
            you consent to receive text messages from us regarding your inquiry
            or our services. Message and data rates may apply. You can opt out
            of receiving text messages at any time by replying “STOP” to any
            text message you receive from us.
          </p>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Information Sharing and Disclosure
          </h2>
          <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
            We do not sell or rent your personal information to third parties.
            We may share your information in the following circumstances:
          </p>
          <ul className="list-disc pl-5 mb-4 text-gray-700 text-lg leading-7">
            <li>
              <strong>Service Providers:</strong> We may share your information
              with our service providers who perform services on our behalf,
              such as marketing, customer service, or technical support.
            </li>
            <li>
              <strong>Legal Requirements:</strong> We may disclose your
              information if required by law, regulation, or legal process.
            </li>
          </ul>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Data Security
          </h2>
          <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
            We implement reasonable security measures to protect your personal
            information from unauthorized access, use, disclosure, alteration,
            or destruction. However, no method of transmission over the internet
            or electronic storage is completely secure.
          </p>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Your Rights and Choices
          </h2>
          <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
            <strong>Opting Out:</strong> You may opt out of receiving marketing
            communications from us by following the instructions in those
            communications or contacting us directly.
          </p>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Contact Us
          </h2>
          <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
            If you have any questions or concerns about this Privacy Policy or
            our privacy practices, please contact us at:
          </p>
          <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
            <strong>Global Auto Experts</strong>
            <br />
            30 S 15th St Ste 1550 Num 173221
            <br />
            Philadelphia, PA 19102
            <br />
            Phone: +1-888-711-6990
          </p>
        </div>
      </div>

      {/* Find Your Orders Section */}
      <div className="bg-blue-600 py-5">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between px-5">
          <div className="flex flex-col items-center md:items-start gap-1 text-center md:text-left">
            <p className="text-white md:text-5xl text-3xl font-semibold mb-3 md:mb-0">
              FIND YOUR ORDERS?
            </p>
            <span className="md:text-4xl text-2xl text-white">
              +1-888-711-6990
            </span>
          </div>
          <button
            onClick={() => navigate("/part-request")}
            className="bg-yellow-400 text-black font-bold py-2 px-6 mt-4 md:mt-0 rounded-md hover:bg-yellow-300"
          >
            Find My Part Now
          </button>
        </div>
      </div>

      {/* Bottom Carousel Section */}
      <BottomCarousel />
    </div>
  );
};

export default PrivacyPolicy;
