import React from "react";
import Banner from "../components/common/Banner";
import banner from "../assets/part.jpg";
import BottomCarousel from "../components/common/BottomCarousel";
import { useNavigate } from "react-router-dom";

const TermsAndCondition = () => {
  const navigate = useNavigate();

  return (
    <div>
      {/* Banner Section */}
      <Banner pageName="Terms and Conditions" backgroundImage={banner} />

      {/* SMS Terms & Conditions Section */}
      <div className="bg-white md:p-10 p-5">
        <div className="max-w-6xl mx-auto px-5">
          <h2 className="text-3xl font-bold text-blue-600 mb-6 text-start">
            SMS Terms & Conditions
          </h2>

          <h3 className="text-2xl font-semibold text-gray-800 mb-4">SMS Consent Communication</h3>
          <p className="text-gray-700 text-lg leading-7 mb-4 text-justify">
            The information (Phone Numbers) obtained as part of the SMS consent process will not be shared with third parties for marketing purposes.
          </p>

          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Types of SMS Communications</h3>
          <p className="text-gray-700 text-lg leading-7 mb-4 text-justify">
            If you have consented to receive text messages from Global Auto Experts, you may receive messages related to the following:
          </p>
          <ul className="list-disc pl-8 text-gray-700 text-lg leading-7 mb-4">
            <li>Appointment reminders</li>
            <li>Follow-up messages</li>
            <li>Billing inquiries</li>
            <li>Promotions or offers (if applicable)</li>
          </ul>
          <p className="text-gray-700 text-lg leading-7 mb-4 text-justify">
            Example: "Hello, this is a friendly reminder of your upcoming appointment with Dr. [Name] at [Location] on [Date] at [Time]. Reply STOP to opt out of SMS messaging at any time."
          </p>

          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Message Frequency</h3>
          <p className="text-gray-700 text-lg leading-7 mb-4 text-justify">
            Message frequency may vary depending on the type of communication. For example, you may receive up to [X] SMS messages per week related to your requirements.
          </p>
          <p className="text-gray-700 text-lg leading-7 mb-4 text-justify">
            Example: "Message frequency may vary. You may receive up to 2 SMS messages per week regarding your appointments or account status."
          </p>

          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Potential Fees for SMS Messaging</h3>
          <p className="text-gray-700 text-lg leading-7 mb-4 text-justify">
            Please note that standard message and data rates may apply, depending on your carrier’s pricing plan. These fees may vary if the message is sent domestically or internationally.
          </p>

          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Opt-In Method</h3>
          <p className="text-gray-700 text-lg leading-7 mb-4 text-justify">
            You may opt-in to receive SMS messages from Global Auto Experts in the following ways:
          </p>
          <ul className="list-disc pl-8 text-gray-700 text-lg leading-7 mb-4">
            <li>Verbally, during a conversation</li>
            <li>By submitting an online form</li>
            <li>By filling out a paper form</li>
          </ul>

          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Opt-Out Method</h3>
          <p className="text-gray-700 text-lg leading-7 mb-4 text-justify">
            You can opt-out of receiving SMS messages at any time. To do so, simply reply "STOP" to any SMS message you receive. Alternatively, you can contact us directly to request removal from our messaging list.
          </p>
        </div>
      </div>

      {/* Pennsylvania Tax Section */}
      <div className="bg-white md:p-10 p-5">
        <div className="max-w-6xl mx-auto px-5">
          <h2 className="text-3xl font-bold text-blue-600 mb-6 text-start">
            Pennsylvania Tax
          </h2>
          <p className="text-gray-700 text-lg leading-7 mb-4 text-justify">
            Pennsylvania sales or use tax may be due in connection with the purchase and delivery of tangible personal property to Pennsylvania individuals and businesses.
          </p>
          <p className="text-gray-700 text-lg leading-7 text-justify">
            The purchaser is required to file a use tax return if tax is due in connection with the purchase and delivery in the Commonwealth. This notice is required pursuant to the provisions of the Tax Reform Code of 1971. 72 P.S. § 7213.2.
          </p>
        </div>
      </div>

      {/* Find Your Orders Section */}
      <div className="bg-blue-600 py-5">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between px-5">
          <div className="flex flex-col items-center md:items-start gap-1 text-center md:text-left">
            <p className="text-white md:text-5xl text-3xl font-semibold mb-3 md:mb-0">
              FIND YOUR ORDERS?
            </p>
            <span className="md:text-4xl text-2xl text-white">
              +1-888-711-6990
            </span>
          </div>
          <button
            onClick={() => navigate("/part-request")}
            className="bg-yellow-400 text-black font-bold py-2 px-6 mt-4 md:mt-0 rounded-md hover:bg-yellow-300"
          >
            Find My Part Now
          </button>
        </div>
      </div>

      {/* Bottom Carousel Section */}
      <BottomCarousel />
    </div>
  );
};

export default TermsAndCondition;
